export const CREATE_STAGE = "CREATE_STAGE";
export const DELETE_STAGE_ID = "DELETE_STAGE_ID";

export const SELECT_STAGE_ID = "SELECT_STAGE_ID";
export const SELECT_TOOL_ID = "SELECT_TOOL_ID";
export const SELECT_TOOL_ITEM = "SELECT_TOOL_ITEM";
export const SELECT_DEFINITION_ID = "SELECT_DEFINITION_ID";
export const UPDATE_PAINTING_STATE = "UPDATE_PAINTING_STATE";
export const UPDATE_PLAYBACK_STATE = "UPDATE_PLAYBACK_STATE";
export const UPDATE_KEYPICKER_STATE = "UPDATE_KEYPICKER_STATE";

export const UPSERT_ACTOR = "UPSERT_ACTOR";
export const DELETE_ACTOR = "DELETE_ACTOR";
export const UPDATE_STAGE_SETTINGS = "UPDATE_STAGE_SETTINGS";
export const SAVE_INITIAL_GAME_STATE = "SAVE_INITIAL_GAME_STATE";
export const RESTORE_INITIAL_GAME_STATE = "RESTORE_INITIAL_GAME_STATE";

export const UPSERT_CHARACTER = "UPSERT_CHARACTER";
export const DELETE_CHARACTER = "DELETE_CHARACTER";
export const CREATE_CHARACTER_VARIABLE = "CREATE_CHARACTER_VARIABLE";
export const DELETE_CHARACTER_VARIABLE = "DELETE_CHARACTER_VARIABLE";
export const CREATE_CHARACTER_FLOW_CONTAINER = "CREATE_CHARACTER_FLOW_CONTAINER";
export const CREATE_CHARACTER_EVENT_CONTAINER = "CREATE_CHARACTER_EVENT_CONTAINER";
export const DELETE_CHARACTER_APPEARANCE = "DELETE_CHARACTER_APPEARANCE";

export const ADVANCE_GAME_STATE = "ADVANCE_GAME_STATE";
export const INPUT_FOR_GAME_STATE = "INPUT_FOR_GAME_STATE";
export const STEP_BACK_GAME_STATE = "STEP_BACK_GAME_STATE";

export const EDIT_RULE_RECORDING = "EDIT_RULE_RECORDING";
export const SETUP_RECORDING_FOR_ACTOR = "SETUP_RECORDING_FOR_ACTOR";
export const SETUP_RECORDING_FOR_CHARACTER = "SETUP_RECORDING_FOR_CHARACTER";
export const CANCEL_RECORDING = "CANCEL_RECORDING";
export const FINISH_RECORDING = "FINISH_RECORDING";
export const SET_RECORDING_EXTENT = "SET_RECORDING_EXTENT";
export const START_RECORDING = "START_RECORDING";
export const UPDATE_RECORDING_CONDITION = "UPDATE_RECORDING_CONDITION";
export const UPDATE_RECORDING_ACTIONS = "UPDATE_RECORDING_ACTIONS";
export const TOGGLE_RECORDING_SQUARE_IGNORED = "TOGGLE_RECORDING_SQUARE_IGNORED";

export const UPDATE_MODAL_STATE = "UPDATE_MODAL_STATE";
export const UPDATE_TUTORIAL_STATE = "UPDATE_TUTORIAL_STATE";

export const UPSERT_GLOBAL = "UPSERT_GLOBAL";
export const DELETE_GLOBAL = "DELETE_GLOBAL";
export const UPDATE_WORLD_METADATA = "UPDATE_WORLD_METADATA";
